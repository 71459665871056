import { useRef } from 'react';
import SecdKi1 from '../assets/img/second-sec/secd-ki1.webp';
import SecdKi2 from '../assets/img/second-sec/secd-ki2.webp';
import SecdKi3 from '../assets/img/second-sec/secd-ki3.webp';
import SecdLeftImg from '../assets/img/second-sec/secd-left-img.webp';
import SecdLeftImgRES from '../assets/img/second-sec/secd-left-img-hztl.webp';
import SecdRightImg from '../assets/img/second-sec/secd-right-img.webp';
import SecdRightImgRES from '../assets/img/second-sec/secd-right-img-hztl.webp';

import TV1 from '../assets/img/second-sec/tv-1.webp';
import TV2 from '../assets/img/second-sec/tv-2.webp';
import TV3 from '../assets/img/second-sec/tv-3.webp';
import TV4 from '../assets/img/second-sec/tv-4.webp';
import TV5 from '../assets/img/second-sec/tv-5.webp';
import TV6 from '../assets/img/second-sec/tv-6.webp';

import TVPrice1 from '../assets/img/second-sec/tv-price-1.webp';
import TVPrice2 from '../assets/img/second-sec/tv-price-2.webp';
import TVPrice3 from '../assets/img/second-sec/tv-price-3.webp';
import TVPrice4 from '../assets/img/second-sec/tv-price-4.webp';
import TVPrice5 from '../assets/img/second-sec/tv-price-5.webp';
import TVPrice6 from '../assets/img/second-sec/tv-price-6.webp';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const tvData = [
    { tvimg: TV1, tvproprice: TVPrice1, productLink : 'https://www.flipkart.com/kodak-7xpro-series-80-cm-32-inch-hd-ready-led-smart-android-tv/p/itm9bf52b610ff2b?pid=TVSGQFRYVV54B3AQ&lid=LSTTVSFUYG8H8NZCGBBRHKNHU&marketplace=FLIPKART&store=ckf%2Fczl&srno=b_1_4&otracker=product_breadCrumbs_KODAK%20Televisions&fm=organic&iid=8d5d4b4a-b437-463e-adad-c6033e7a38ec.TVSFUYG8H8NZCGBB.SEARCH&ppt=browse&ppn=browse' },
    { tvimg: TV2, tvproprice: TVPrice2, productLink : 'https://www.flipkart.com/kodak-7xpro-series-80-cm-32-inch-hd-ready-led-smart-android-tv/p/itm9bf52b610ff2b?pid=TVSGQFRY5HUKB8WM&lid=LSTTVSFUYG8H8NZCGBBRHKNHU&marketplace=FLIPKART&store=ckf%2Fczl&srno=b_1_4&otracker=product_breadCrumbs_KODAK%20Televisions&fm=organic&iid=8d5d4b4a-b437-463e-adad-c6033e7a38ec.TVSFUYG8H8NZCGBB.SEARCH&ppt=browse&ppn=browse' },
    { tvimg: TV3, tvproprice: TVPrice3, productLink : 'https://www.flipkart.com/kodak-7xpro-series-80-cm-32-inch-hd-ready-led-smart-android-tv/p/itm9bf52b610ff2b?pid=TVSGQFRY8DWD3GPH&lid=LSTTVSFUYG8H8NZCGBBRHKNHU&marketplace=FLIPKART&store=ckf%2Fczl&srno=b_1_4&otracker=product_breadCrumbs_KODAK%20Televisions&fm=organic&iid=8d5d4b4a-b437-463e-adad-c6033e7a38ec.TVSFUYG8H8NZCGBB.SEARCH&ppt=browse&ppn=browse' },
    { tvimg: TV4, tvproprice: TVPrice4, productLink : 'https://www.flipkart.com/kodak-7xpro-126-cm-50-inch-ultra-hd-4k-led-smart-android-tv-40w-sound-output-bezel-less-design/p/itm41b7ae3c37153?pid=TVSGQAREYSW9DYY5&lid=LSTTVSG8TP8HYRTEH2B1OHEEX&marketplace=FLIPKART&store=ckf%2Fczl&srno=b_1_13&otracker=product_breadCrumbs_KODAK%20Televisions&fm=organic&iid=8d5d4b4a-b437-463e-adad-c6033e7a38ec.TVSG8TP8HYRTEH2B.SEARCH&ppt=browse&ppn=browse' },
    { tvimg: TV5, tvproprice: TVPrice5, productLink : 'https://www.flipkart.com/kodak-7xpro-139-cm-55-inch-ultra-hd-4k-led-smart-android-tv-40w-sound-output-bezel-less-design/p/itm5776f7455016f?pid=TVSGMXD2S9EYASHA&lid=LSTTVSG8TP8DH2MR9GJG6IFHG&marketplace=FLIPKART&store=ckf%2Fczl&srno=b_1_17&otracker=product_breadCrumbs_KODAK%20Televisions&fm=organic&iid=8d5d4b4a-b437-463e-adad-c6033e7a38ec.TVSG8TP8DH2MR9GJ.SEARCH&ppt=browse&ppn=browse' },
    { tvimg: TV6, tvproprice: TVPrice6, productLink : 'https://www.flipkart.com/kodak-164-cm-65-inch-qled-ultra-hd-4k-smart-google-tv-dolby-atmos-vision/p/itm380d55ec3d0fb?pid=TVSGQEFU59HPZQRH&lid=LSTTVSGHBGYTGBH3PZFEQJUML&marketplace=FLIPKART&q=kodak+qled&store=ckf%2Fczl&srno=s_1_3&otracker=search&otracker1=search&fm=Search&iid=4ee4aeb4-da71-4664-b028-a3566bae08a9.TVSGHBGYTGBH3PZF.SEARCH&ppt=sp&ppn=sp&ssid=r3vb9hs2mo0000001664284498978&qH=2e7651e130bbb79e' },
]

export default function SecdSec() {

    const secondSec = useRef(null);

    let secondCard = useRef([]);
    secondCard.current = [];

    const addtoRefs = (item) => {
        if (item && !secondCard.current.includes(item)) {
            secondCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        secondCard.current.forEach((item, index) => {
            gsap.timeline({
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: item,
                    // markers: true,
                    start: "top center+=50",
                    toggleActions: 'play none none reverse',
                },
            }).from(item, {
                autoAlpha: 0,
                x: '50px',
            }).to(item, {
                autoAlpha: 1,
                x: '0',
            })
        })

    })

    return (
        <>
            <section ref={secondSec} className='left-right-sticky-section'>
                <div className="container">
                    <div className="d-md-flex gap-3 -part-divi">
                        <div className="sticky start-0">
                            <div className="stickybg-secton">
                                <div className="img-div secd-tv-img ">
                                    {/* <img src={SecdLeftImg} alt="SecdLeftImg" className='img-fluid' /> */}
                                    <picture>
                                        <source media="(min-width:1000px)" srcSet={SecdLeftImg} />
                                        <source media="(min-width:768px)" srcSet={SecdLeftImgRES} />
                                        <img loading='lazy' src={SecdLeftImgRES} className="img-fluid qled-img" alt="SecdLeftImg" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <div className="kodak-center-part">
                            <div className="row">
                                <div className="col-md-11 mx-auto">
                                    <div className="">
                                        <div className="img-div second-ki1-img ">
                                            <img loading='lazy' src={SecdKi1} alt="SecdKi1" className='img-fluid w-100' />
                                        </div>
                                        <div className="img-div second-ki2-img ">
                                            <img loading='lazy' src={SecdKi3} alt="SecdKi3" className='img-fluid w-100' />
                                        </div>
                                        <div className="img-div second-ki2-img ">
                                            <img loading='lazy' src={SecdKi2} alt="SecdKi2" className='img-fluid w-100' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="d-flex gap-3 second-stick-part">
                        <div className="kodak-center-part">
                            <div className="row">

                                {
                                    tvData && tvData.map((item, index) => {
                                        return (
                                            <div id={`section-${index + 1}`} ref={addtoRefs} key={index} className="col-6 mb-4">
                                                <div className="product-div">
                                                    <a href={item.productLink} target="_blank">
                                                        <div className="img-div second-prod-img p-md-4">
                                                            <img loading='lazy' src={item.tvimg} alt="tvimg" className='img-fluid w-100 mb-3' />
                                                            <img loading='lazy' src={item.tvproprice} alt="tvimg" className='img-fluid' />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                        </div>
                        <div className="sticky start-100">
                            <div className="stickybg-secton">
                                <div className="img-div secd-tv-img ">
                                    {/* <img loading='lazy' src={SecdRightImg} alt="SecdRightImg" className='img-fluid' /> */}
                                    <picture>
                                        <source media="(min-width:1000px)" srcSet={SecdRightImg} />
                                        <source media="(min-width:768px)" srcSet={SecdRightImgRES} />
                                        <img loading='lazy' src={SecdRightImgRES} className="img-fluid qled-img" alt="SecdRightImg" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
