import React from 'react'
import bezelSmainimg from '../assets/img/Bezel-less/bezel-less.webp';
import bezelSdi1 from '../assets/img/Bezel-less/BezelLess.webp';
import bezelSdi2 from '../assets/img/Bezel-less/Sleek Design.webp';
import bezelSdi3 from '../assets/img/Bezel-less/View Angle.webp';
import Frame from '../assets/img/dv-sec/frame.webp';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);

const bezelSArr = [
    { imgurl: bezelSdi1 },
    { imgurl: bezelSdi2 },
    { imgurl: bezelSdi3 },
]

export default function BezelSec() {

    const bezelSSec = useRef(null);

    let bezelSCard = useRef([]);
    bezelSCard.current = [];

    const bezelSaddtoRefs = (item) => {
        if (item && !bezelSCard.current.includes(item)) {
            bezelSCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        bezelSCard.current.forEach((item, index) => {
            gsap.timeline({
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: item,
                    // markers: true,
                    start: "top center+=100",
                    toggleActions: 'play none none reverse',
                    ease: "slow(0.5, 0.8)",
                    scrub: 1,
                    duration: 2,
                    triggerHook: 0,
                },
            }).from(item, {
                autoAlpha: 0,
                scale: '1.5',
            }).to(item, {
                autoAlpha: 1,
                scale: '1',
            })
        })

    })

    return (
        <section className="work-station section-space overflow-hidden">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-5 order-2 order-md-1">
                        <div className='d-flex spect-frame'>
                            {
                                bezelSArr && bezelSArr.map((item, index) => {
                                    return (
                                        <div ref={bezelSaddtoRefs} key={index} className='mt-md-5'>
                                            <div className="img-div common-tv-imgbottom-div">
                                                <img loading='lazy' src={item.imgurl} alt="imgurl" className='img-fluid' />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-md-7 order-1 order-md-2">

                        <div className="img-video-common-part">
                            <div className="main-img-commom">
                                <div className="fram-div">
                                    <img loading='lazy' src={Frame} alt="Frame" className='img-fluid' />
                                </div>
                                <div className="img-div common-tv-imgmain-div">
                                    <img loading='lazy' src={bezelSmainimg} alt="bezelSmainimg" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </div>
        </section>
    )
}
