import React from 'react'


import DVwv from '../assets/img/dv-sec/178 wide view Angle.webp';
import DVbc from '../assets/img/dv-sec/better contrest.webp';
import DVhb from '../assets/img/dv-sec/higher brightness.webp';
import DVvc from '../assets/img/dv-sec/vivid color.webp';

import Frame from '../assets/img/dv-sec/frame.webp';

import { gsap } from 'gsap';
import DVvideomain from '../assets/img/dv-sec/Picture Quality2-1.mp4';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);

const dolbyVissionArr = [
    { imgurl: DVwv },
    { imgurl: DVbc },
    { imgurl: DVhb },
    { imgurl: DVvc },
]


export default function DAsection() {

    const dolVisSec = useRef(null);

    let dolVisCard = useRef([]);
    dolVisCard.current = [];

    const dolVisaddtoRefs = (item) => {
        if (item && !dolVisCard.current.includes(item)) {
            dolVisCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        const dolVisVideo = document.querySelector('#dolVisVideo');
        gsap.timeline({
            scrollTrigger: {
                trigger: dolVisVideo,
                // markers: true,
                start: "top center",
                end: 'bottom +=50%',
                onEnter: () => dolVisVideo.play(),
                onLeave: () => dolVisVideo.pause(),
                onEnterBack: () => dolVisVideo.play(),
                onLeaveBack: () => dolVisVideo.pause(),
            }
        });

        dolVisCard.current.forEach((item, index) => {
            gsap.timeline({
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: item,
                    // markers: true,
                    start: "top center+=100",
                    toggleActions: 'play none none reverse',
                    ease: "slow(0.5, 0.8)",
                    scrub: 1,
                    duration: 2,
                    triggerHook: 0,
                },
            }).from(item, {
                autoAlpha: 0,
                scale: '1.5',
            }).to(item, {
                autoAlpha: 1,
                scale: '1',
            })
        })

    })

    return (
        <section ref={dolVisSec} className="dolby-atmos section-space overflow-hidden">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7">
                        <div className="img-video-common-part">
                            <div className="main-img-commom">
                                <div className="fram-div">
                                    <img  loading='lazy' src={Frame} alt="Frame" className='img-fluid' />
                                </div>
                                
                                <div className="video-part">
                                    <video id="dolVisVideo" width="100%" height="100%" loop muted>
                                        <source src={DVvideomain} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className='d-flex spect-frame'>
                            {
                                dolbyVissionArr && dolbyVissionArr.map((item, index) => {
                                    return (
                                        <div ref={dolVisaddtoRefs} id={`section-${index + 1}`} key={index} className=''>
                                            <div className="img-div common-tv-imgbottom-div">
                                                <img  loading='lazy' src={item.imgurl} alt="imgurl" className='img-fluid' />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>

                {/* <div className="row">
                    {
                        dolbyVissionArr && dolbyVissionArr.map((item, index) => {
                            return (
                                <div key={index} className='col-3'>
                                    <div className="img-div common-tv-imgbottom-div py-5">
                                        <img  loading='lazy' src={item.imgurl} alt="imgurl" className='img-fluid' />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> */}
            </div>
        </section>
    )
}
