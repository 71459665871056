import lastSecMainImg from '../assets/img/Customer Support/Customer Support.webp';

import lastImgArr1 from '../assets/img/Customer Support/3000.webp';
import lastImgArr2 from '../assets/img/Customer Support/Doorstep.webp';
import lastImgArr3 from '../assets/img/Customer Support/Free.webp';

import hastagImg from '../assets/img/Customer Support/HAstag.webp';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);

const lastArrImg = [
    { imgurl: lastImgArr1 },
    { imgurl: lastImgArr2 },
    { imgurl: lastImgArr3 },
]

export default function LastSec() {


    const lastSecSec = useRef(null);

    let lastSecCard = useRef([]);
    lastSecCard.current = [];

    const lastSecaddtoRefs = (item) => {
        if (item && !lastSecCard.current.includes(item)) {
            lastSecCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        if (window.innerWidth > 767){
            lastSecCard.current.forEach((item, index) => {
                gsap.timeline({
                    scrollTrigger: {
                        id: `section-${index + 1}`,
                        trigger: item,
                        // markers: true,
                        start: "top center+=100",
                        toggleActions: 'play none none reverse',
                        ease: "slow(0.5, 0.8)",
                        scrub: 1,
                        duration: 2,
                        triggerHook: 0,
                    },
                }).from(item, {
                    autoAlpha: 0,
                    scale: '1.5',
                }).to(item, {
                    autoAlpha: 1,
                    scale: '1',
                })
            })
        }
        

    })

    return (
        <section className='overflow-hidden'>
            <div className="container">
                <div className="row">
                    <div className="col-12 mx-auto position-relative">
                        <div className="final-section-div ">
                            <div className="img-div ">
                                <img loading='lazy' src={lastSecMainImg} alt="lastSecMainImg" className='img-fluid' />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        {
                            lastArrImg && lastArrImg.map((item, index) => {
                                return (
                                    <div ref={lastSecaddtoRefs} key={index} className='col-4'>
                                        <div className="img-div common-tv-imgbottom-div text-center">
                                            <img loading='lazy' src={item.imgurl} alt="imgurl" className='img-fluid' />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="row">
                        <div className="col-10 mx-auto">
                            <div className="img-div common-tv-imgbottom-div text-center">
                                <img loading='lazy' src={hastagImg} alt="hastagImg" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
