
import React from 'react'
import gamingSmainimg from '../assets/img/gaming/Game.mp4';
import gamingSdi1 from '../assets/img/gaming/60Hz.webp';
import gamingSdi2 from '../assets/img/gaming/1.4.webp';
import gamingSdi3 from '../assets/img/gaming/Game Mode.webp';
import gamingSdi4 from '../assets/img/gaming/HDMI.webp';
import Frame from '../assets/img/dv-sec/frame.webp';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);

const gamingSArr = [
    { imgurl: gamingSdi1 },
    { imgurl: gamingSdi2 },
    { imgurl: gamingSdi3 },
    { imgurl: gamingSdi4 },
]

export default function GamingSec() {

    const gamingSSec = useRef(null);

    let gamingSCard = useRef([]);
    gamingSCard.current = [];

    const gamingSaddtoRefs = (item) => {
        if (item && !gamingSCard.current.includes(item)) {
            gamingSCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        const dolVisVideo = document.querySelector('#gaminVideo');
        gsap.timeline({
            scrollTrigger: {
                trigger: dolVisVideo,
                // markers: true,
                start: "top center",
                end: 'bottom +=50%',
                onEnter: () => dolVisVideo.play(),
                onLeave: () => dolVisVideo.pause(),
                onEnterBack: () => dolVisVideo.play(),
                onLeaveBack: () => dolVisVideo.pause(),
            }
        });

        gamingSCard.current.forEach((item, index) => {
            gsap.timeline({
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: item,
                    // markers: true,
                    start: "top center+=100",
                    toggleActions: 'play none none reverse',
                    ease: "slow(0.5, 0.8)",
                    scrub: 1,
                    duration: 2,
                    triggerHook: 0,
                },
            }).from(item, {
                autoAlpha: 0,
                scale: '1.5',
            }).to(item, {
                autoAlpha: 1,
                scale: '1',
            })
        })

    })

    return (
        <section className="work-station section-space overflow-hidden">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-5 order-2 order-md-1">
                        <div className='d-flex spect-frame'>
                            {
                                gamingSArr && gamingSArr.map((item, index) => {
                                    return (
                                        <div ref={gamingSaddtoRefs} key={index} className='mt-md-5'>
                                            <div className="img-div common-tv-imgbottom-div">
                                                <img loading='lazy' src={item.imgurl} alt="imgurl" className='img-fluid' />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-md-7 order-1 order-md-2">
                        <div className="img-video-common-part">
                            <div className="main-img-commom">
                                <div className="fram-div">
                                    <img loading='lazy' src={Frame} alt="Frame" className='img-fluid' />
                                </div>
                                
                                <div className="video-part">
                                    <video id="gaminVideo" width="100%" height="100%" loop muted>
                                        <source src={gamingSmainimg} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>

                    </div>
                    




                </div>

            </div>
        </section>
    )
}

