import { useRef, useEffect, useLayoutEffect } from 'react'
import FirstA11 from '../assets/img/first-sec/Android.gif';
import FirstGTV from '../assets/img/first-sec/google.gif';
import FirstTV from '../assets/img/first-sec/first-tv.webp';
import FirstNYT from '../assets/img/first-sec/first-nyt.webp';
import First32 from '../assets/img/first-sec/first-32-42.webp';
import First55 from '../assets/img/first-sec/first-55-65.webp';
import First75 from '../assets/img/first-sec/first-75.webp';
import FrameFirst from '../assets/img/first-sec/Frame.webp';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function FirstSec() {

    const firstSec = useRef(null);
    const firstimgDiv = useRef(null);
    const firstimgfapart = useRef(null);

    window.$(document).ready(function () {

        if (window.innerWidth > 767) {
            gsap.timeline({
                scrollTrigger: {
                    trigger: firstSec.current,
                    start: "top top",
                    // markers: true,
                    scrub: 1,
                    pin: true,
                    duration: 1,
                },
            }).from(firstimgDiv.current, 10, {
                scale: 2,
                position: 'relative',
                zIndex: 9,
                transformOrigin: "25% 50%"
            })
            .to(firstimgDiv.current, 10, {
                scale: 1,
                duration: 1,
            }, 'first')
            .from(firstimgfapart.current, 2, {
                x: '100px',
                autoAlpha: 0,
            }, 'first')
            .to(firstimgfapart.current, 2, {
                x: '0',
                autoAlpha: 1,
                duration: 1,
                delay: 1,
            }, 'first')
        }
        else {
            gsap.timeline({
                scrollTrigger: {
                    trigger: firstSec.current,
                    start: "top top",
                    // markers: true,
                    scrub: 1,
                    pin: true,
                    duration: 1,
                },
            })
                .from(firstimgDiv.current, 10, {
                    scale: 3,
                    position: 'relative',
                    zIndex: 9,
                    transformOrigin: "90% 25%"
                })
                .to(firstimgDiv.current, 10, {
                    scale: 1,
                    duration: 1,
                }, 'first')
                .from(firstimgfapart.current, 2, {
                    x: '100px',
                    autoAlpha: 0,
                }, 'first')
                .to(firstimgfapart.current, 2, {
                    x: '0',
                    autoAlpha: 1,
                    duration: 1,
                    delay: 1,
                }, 'first')
        }



    });



    return (
        <>
            <section id='first-sec overflow-hidden' className='first-sec' ref={firstSec}>
                <div className="container pt-4">
                    <div className="row">

                        <div className="col-md-8 ">
                            <div className="img-div first-tv-img">
                                <img loading='lazy' ref={firstimgDiv} id='firstimgDiv' src={FirstTV} alt="FirstTV" className='img-fluid' />
                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className='first-gtand11-res' ref={firstimgfapart}>
                                <div className="img-div first-gtv-img mt-md-5">
                                    <div className="frame-first">
                                        <img loading='lazy' src={FrameFirst} alt="FrameFirst" className='img-fluid' />
                                    </div>
                                    <div className="main-first-img">
                                        <img loading='lazy' src={FirstGTV} alt="FirstGTV" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="img-div first-gtv-img">
                                    <div className="frame-first">
                                        <img loading='lazy' src={FrameFirst} alt="FrameFirst" className='img-fluid' />
                                    </div>
                                    <div className="main-first-img">
                                        <img loading='lazy' src={FirstA11} alt="FirstA11" className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-12 mx-auto">
                            <div className="img-div first-tv-img py-3">
                                <img loading='lazy' src={FirstNYT} alt="FirstNYT" className='img-fluid' />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="img-div first-tv-img py-md-5">
                                <img loading='lazy' src={First32} alt="First32" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="img-div first-tv-img py-md-5">
                                <img loading='lazy' src={First55} alt="First55" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="img-div first-tv-img py-md-5">
                                <img loading='lazy' src={First75} alt="First75" className='img-fluid' />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
