import Imgurl1 from '../assets/img/third-sec/third-sec-img (1).webp';
import Imgurl2 from '../assets/img/third-sec/third-sec-img (2).webp';

import Imgurl4 from '../assets/img/third-sec/third-sec-img (3).webp';
import Imgurl5 from '../assets/img/third-sec/third-sec-img (5).webp';
import Imgurl6 from '../assets/img/third-sec/third-sec-img (6).webp';
import Imgurl7 from '../assets/img/third-sec/third-sec-img (7).webp';
import Imgurl8 from '../assets/img/third-sec/third-sec-img (8).webp';
import Imgurl9 from '../assets/img/third-sec/third-sec-img (9).webp';
import Imgurl10 from '../assets/img/third-sec/third-sec-img (10).webp';
import Imgurl11 from '../assets/img/third-sec/third-sec-img (11).webp';
import Imgurl12 from '../assets/img/third-sec/third-sec-img (12).webp';
import Imgurl13 from '../assets/img/third-sec/third-sec-img (13).webp';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);

const thirdImgData = [
    { imgurl: Imgurl1 },
    { imgurl: Imgurl2 },
    { imgurl: Imgurl4 },
    { imgurl: Imgurl5 },
    { imgurl: Imgurl6 },
    { imgurl: Imgurl7 },
    { imgurl: Imgurl8 },
    { imgurl: Imgurl9 },
    { imgurl: Imgurl10 },
    { imgurl: Imgurl11 },
    { imgurl: Imgurl12 },
    { imgurl: Imgurl13 },
];

export default function ThirdSec() {

    const thirdSec = useRef(null);

    let threeCard = useRef([]);
    threeCard.current = [];

    const threeaddtoRefs = (item) => {
        if (item && !threeCard.current.includes(item)) {
            threeCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        threeCard.current.forEach((item, index) => {
            gsap.timeline({
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: item,
                    // markers: true,
                    start: "top center+=100",
                    toggleActions: 'play none none reverse',
                    ease: "slow(0.5, 0.8)",
                    scrub: 1,
                    duration: 2,
                    triggerHook: 0,
                },
            }).from(item, {
                autoAlpha: 0,
                scale: '1.5',
            }).to(item, {
                autoAlpha: 1,
                scale: '1',
            })
        })

    })

    return (
        <section ref={thirdSec} className='third-sec-grid overflow-hidden'>
            <div className="container">
                <div className="row align-items-start justify-content-center">
                    {
                        thirdImgData && thirdImgData.map((item, index) => {
                            return (
                                <div ref={threeaddtoRefs} id={`section-${index + 1}`} key={index} className="col-3 p-md-4">
                                    <div className={`img-div third-sec-img-div third-sec-img-${index}`}>
                                        <img loading='lazy' src={item.imgurl} alt="third-imgurl" className='img-fluid' />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
