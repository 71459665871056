
import React from 'react'
import WorkSmainimg from '../assets/img/workstation/Group 92.webp';
import WorkSdi1 from '../assets/img/workstation/Airplay.webp';
import WorkSdi2 from '../assets/img/workstation/Chromecast.webp';
import Frame from '../assets/img/dv-sec/frame.webp';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);

const workSArr = [
    { imgurl: WorkSdi1 },
    { imgurl: WorkSdi2 },
]

export default function WorkStaSec() {

    const WorkSSec = useRef(null);

    let WorkSCard = useRef([]);
    WorkSCard.current = [];

    const WorkSaddtoRefs = (item) => {
        if (item && !WorkSCard.current.includes(item)) {
            WorkSCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        WorkSCard.current.forEach((item, index) => {
            gsap.timeline({
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: item,
                    // markers: true,
                    start: "top center+=100",
                    toggleActions: 'play none none reverse',
                    ease: "slow(0.5, 0.8)",
                    scrub: 1,
                    duration: 2,
                    triggerHook: 0,
                },
            }).from(item, {
                autoAlpha: 0,
                scale: '1.5',
            }).to(item, {
                autoAlpha: 1,
                scale: '1',
            })
        })

    })

    return (
        <section className="work-station section-space overflow-hidden">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7">
                        <div className="img-video-common-part">
                            <div className="main-img-commom">
                                <div className="fram-div">
                                    <img  loading='lazy' src={Frame} alt="Frame" className='img-fluid' />
                                </div>
                                <div className="img-div common-tv-imgmain-div">
                                    <img  loading='lazy' src={WorkSmainimg} alt="WorkSmainimg" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className='d-flex spect-frame'>
                            {
                                workSArr && workSArr.map((item, index) => {
                                    return (
                                        <div ref={WorkSaddtoRefs} key={index} className='mt--md-5 w-75'>
                                            <div className="img-div common-tv-imgbottom-div">
                                                <img  loading='lazy' src={item.imgurl} alt="imgurl" className='img-fluid' />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>


                </div>

            </div>
        </section>
    )
}
