import DisciverTv from '../assets/img/discover/Android 11.webp';
import DisciverTop from '../assets/img/discover/Kodak Wall.webp';
import Slider from './Slider';


export default function Automove() {

    
    return (
        <section className='overflow-hidden'>

            <div className="container">
                <div className="row">
                <div className="col-11 mx-auto">
                        <div className="img-div discover-tv-img py-md-5">
                            <img loading='lazy' src={DisciverTop} alt="DisciverTop" className='img-fluid' />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="img-div discover-tv-img py-md-5">
                            <img loading='lazy' src={DisciverTv} alt="DisciverTv" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
            <Slider/>
        </section>
    )
}
