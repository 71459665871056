import appLogo1 from '../assets/img/applogo/Amazon Prime.webp'
import appLogo2 from '../assets/img/applogo/Disney Hotstar.webp'
import appLogo3 from '../assets/img/applogo/Google Play Movies & TV.webp'
import appLogo4 from '../assets/img/applogo/Jio Cinema.webp'
import appLogo5 from '../assets/img/applogo/Sony Liv.webp'
import appLogo6 from '../assets/img/applogo/Youtube.webp'
import appLogo8 from '../assets/img/applogo/Zee5.webp'
import appLogo9 from '../assets/img/applogo/netflix.webp'
import appLogo7 from '../assets/img/applogo/Google Play Music.webp'

const applogoArr = [
    { imgurl: appLogo1 },
    { imgurl: appLogo2 },
    { imgurl: appLogo3 },
    { imgurl: appLogo4 },
    { imgurl: appLogo5 },
    { imgurl: appLogo6 },
    { imgurl: appLogo7 },
    { imgurl: appLogo8 },
    { imgurl: appLogo9 },
]

export default function Slider() {

    window.$(document).ready(function () {
        var owl = window.$('.owl-app-caro');
        owl.owlCarousel({
            loop: true,
            margin: 10,
            autoplay: true,
            slideTransition: 'linear',
            autoplayTimeout: 5000,
            autoplaySpeed: 5000,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 3,
                },
                767: {
                    items: 3,
                },
                1000: {
                    items: 5,
                   
                }
            }
        });

    });


    return (
        <section className='overflow-hidden'>
            <div className="container">
                <div className="owl-carousel owl-theme owl-app-caro">
                    {
                        applogoArr && applogoArr.map((item, index) => {
                            return (
                                <div key={index} className="owl-slide-applogo">
                                    <img src={item.imgurl} alt={item.imgurl} className='img-fluid' />
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </section>
    )
}
