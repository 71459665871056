import React from 'react'

import DAmainvideo from '../assets/img/da-sec/sound output1.mp4';
import DA40 from '../assets/img/da-sec/40W.webp';
import DADTS from '../assets/img/da-sec/DTS.webp';
import DAdolby from '../assets/img/da-sec/Dolby.webp';
// import Video from './Video';
import Frame from '../assets/img/dv-sec/frame.webp';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);

const dolbyAtmosArr = [
    { imgurl: DA40 },
    { imgurl: DADTS },
    { imgurl: DAdolby },
]

export default function DVsection() {

    const dolAtmSec = useRef(null);

    let dolAtmCard = useRef([]);
    dolAtmCard.current = [];

    const dolAtmaddtoRefs = (item) => {
        if (item && !dolAtmCard.current.includes(item)) {
            dolAtmCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        const dolVisVideo = document.querySelector('#DAmainvideo');
        gsap.timeline({
            scrollTrigger: {
                trigger: dolVisVideo,
                // markers: true,
                start: "top center",
                end: 'bottom +=50%',
                onEnter: () => dolVisVideo.play(),
                onLeave: () => dolVisVideo.pause(),
                onEnterBack: () => dolVisVideo.play(),
                onLeaveBack: () => dolVisVideo.pause(),
            }
        });

        dolAtmCard.current.forEach((item, index) => {
            gsap.timeline({
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: item,
                    // markers: true,
                    start: "top center+=100",
                    toggleActions: 'play none none reverse',
                    ease: "slow(0.5, 0.8)",
                    scrub: 1,
                    duration: 2,
                    triggerHook: 0,
                },
            }).from(item, {
                autoAlpha: 0,
                scale: '1.5',
            }).to(item, {
                autoAlpha: 1,
                scale: '1',
            })
        })

    })

    return (
        <section className="dolby-vision section-space overflow-hidden">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-5 order-2 order-md-1">
                        <div className='d-flex spect-frame'>
                            {
                                dolbyAtmosArr && dolbyAtmosArr.map((item, index) => {
                                    return (
                                        <div ref={dolAtmaddtoRefs} key={index} className=''>
                                            <div className="img-div common-tv-imgbottom-div py-md-5">
                                                <img loading='lazy' src={item.imgurl} alt="imgurl" className='img-fluid' />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-md-7 order-1 order-md-2">

                        <div className="img-video-common-part">
                            <div className="main-img-commom">
                                <div className="fram-div">
                                    <img loading='lazy' src={Frame} alt="Frame" className='img-fluid' />
                                </div>
                                <div className="video-part">
                                    <video id="DAmainvideo" width="100%" height="100%" loop muted>
                                        <source src={DAmainvideo} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    )
}
