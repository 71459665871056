import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';

import qledHeadImg from '../assets/img/qled/wall of fame.webp';
import qledHeadImg2 from '../assets/img/qled/QLED (1).webp';

import qledArrImg1 from '../assets/img/qled/Atmos.webp';
import qledArrImg2 from '../assets/img/qled/Vision.webp';
import qledArrImg3 from '../assets/img/qled/Google TV.webp';
import qledArrImg4 from '../assets/img/qled/MEMC.webp';
import qledArrImg5 from '../assets/img/qled/Mediatek.webp';
import qledArrImg6 from '../assets/img/qled/Qled Display.webp';



gsap.registerPlugin(ScrollTrigger);


const QledArr = [
    { imgurl: qledArrImg1 },
    { imgurl: qledArrImg2 },
    { imgurl: qledArrImg3 },
    { imgurl: qledArrImg4 },
    { imgurl: qledArrImg5 },
    { imgurl: qledArrImg6 },
]

export default function QledSec() {


    const qledSec = useRef(null);

    let qledSCard = useRef([]);
    qledSCard.current = [];

    const qledSaddtoRefs = (item) => {
        if (item && !qledSCard.current.includes(item)) {
            qledSCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        qledSCard.current.forEach((item, index) => {
            gsap.timeline({
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: item,
                    // markers: true,
                    start: "top center+=100",
                    toggleActions: 'play none none reverse',
                    ease: "slow(0.5, 0.8)",
                    scrub: 1,
                    duration: 2,
                    triggerHook: 0,
                },
            }).from(item, {
                y: '50px',
                autoAlpha: 0,
            }).to(item, {
                y: '0',
                autoAlpha: 1,
               
            })
        })

    })




    return (
        <section className='qled overflow-hidden mt-5' >

            <div className="qled-heading-img pt-5" >
                <div className="container">
                    <div className="row">
                        <div className="col-10 mx-auto">
                            <div className="img-div first-gtv-img ">
                                <img  loading='lazy' src={qledHeadImg} alt="qledHeadImg" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-6 mx-auto">
                            <div className="img-div first-gtv-img ">
                                <img  loading='lazy' src={qledHeadImg2} alt="qledHeadImg2" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">


                        {
                            QledArr && QledArr.map((item, index) => {
                                return (
                                    <div ref={qledSaddtoRefs} key={index} className='col-6'>
                                        <div className="img-div common-tv-imgbottom-div">
                                            <img  loading='lazy' src={item.imgurl} alt="imgurl" className='img-fluid' />
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>




        </section >
    )
}
