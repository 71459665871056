// bootstrap
import { useEffect, useRef } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

import './App.css';
import './Respon.css';
import Automove from './section/Automove';
import DAsection from './section/DAsection';
import DVsection from './section/DVsection';
import FirstSec from './section/FirstSec';
import SecdSec from './section/SecdSec';
import ThirdSec from './section/ThirdSec';
import ProcessSec from './section/ProcessSec';
import EasyCSec from './section/EasyCSec';
import WorkStaSec from './section/WorkStaSec';
import BezelSec from './section/BezelSec';
import GooASSec from './section/GooASSec';
import GamingSec from './section/GamingSec';
import QledSec from './section/QledSec';
import LastSec from './section/LastSec';

function Seotags({title,description,keywoard}) {
  window.$('title').text(title);
  window.$('meta[name="description"]').attr('content', description);
  window.$('meta[name="keywords"]').attr('content', keywoard);
  window.$('meta[property="og:title"]').attr('content', title);
  window.$('meta[property="og:url"]').attr('content', window.location.href);
  window.$('meta[property="og:description"]').attr('content', description);
  window.$('meta[name="twitter:title"]').attr('content', title);
  window.$('meta[name="twitter:description"]').attr('content', description);
}


function App() {

  document.onreadystatechange = function () {
    console.log('run')
    if (document.readyState !== "complete") {
      window.$("#lodingdiv").show();
    } else {
      window.$("#lodingdiv").fadeOut();
    }
  };
  

  return (

    <>
    <Seotags title={'Kodak TV'} 
    description={'Kodak TV brings TVs like HD TV, Full HD TV, 4K UHD TV, Smart TV with latest technology. Top rated &amp; selling series like CA Series, 7X Pro Series and more.'} 
    keywoard={'Kodak TV brings TVs like HD TV, Full HD TV, 4K UHD TV, Smart TV with latest technology. Top rated &amp; selling series like CA Series, 7X Pro Series and more.'} />
      <div className="lodingdiv" id="lodingdiv" >
        <div className="wrap">
          <div className="loadingload">
            <div className="bounceball"></div>
            <div className="text">NOW LOADING</div>
          </div>
        </div>
      </div>
      <div className="App">
        <FirstSec />
        <SecdSec />

        <ThirdSec />
        <Automove />

        <DAsection />
        <DVsection />
        <ProcessSec />
        <EasyCSec />
        <WorkStaSec />
        <BezelSec />
        <GooASSec />
        <GamingSec />
        <QledSec />
        <LastSec />


      </div>

    </>
  );
}

export default App;
