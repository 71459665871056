import React from 'react'
import Procmainimg from '../assets/img/Processor/realtek Processor.webp';
import Procmainvideo from '../assets/img/Processor/Pro(1).mp4';
import Procdi1 from '../assets/img/Processor/2GB RAM.webp';
import Procdi2 from '../assets/img/Processor/ARM Cortex.webp';
import Procdi3 from '../assets/img/Processor/Mali 450.webp';
import Procdi4 from '../assets/img/Processor/Quad Core Processor.webp';
import Frame from '../assets/img/dv-sec/frame.webp';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);



const processArr = [
    { imgurl: Procdi1 },
    { imgurl: Procdi2 },
    { imgurl: Procdi3 },
    { imgurl: Procdi4 },
]

export default function ProcessSec() {

    const procssSec = useRef(null);

    let procssCard = useRef([]);
    procssCard.current = [];

    const procssaddtoRefs = (item) => {
        if (item && !procssCard.current.includes(item)) {
            procssCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        const dolVisVideo = document.querySelector('#Processer');
        gsap.timeline({
            scrollTrigger: {
                trigger: dolVisVideo,
                // markers: true,
                start: "top center",
                end: 'bottom +=50%',
                onEnter: () => dolVisVideo.play(),
                onLeave: () => dolVisVideo.pause(),
                onEnterBack: () => dolVisVideo.play(),
                onLeaveBack: () => dolVisVideo.pause(),
            }
        });

        procssCard.current.forEach((item, index) => {
            gsap.timeline({
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: item,
                    // markers: true,
                    start: "top center+=100",
                    toggleActions: 'play none none reverse',
                    ease: "slow(0.5, 0.8)",
                    scrub: 1,
                    duration: 2,
                    triggerHook: 0,
                },
            }).from(item, {
                autoAlpha: 0,
                scale: '1.5',
            }).to(item, {
                autoAlpha: 1,
                scale: '1',
            })
        })

    })

    return (
        <section className="dolby-atmos section-space overflow-hidden">
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-7">
                        <div className="img-video-common-part">
                            <div className="main-img-commom">
                                <div className="fram-div">
                                    <img  loading='lazy' src={Frame} alt="Frame" className='img-fluid' />
                                </div>
                                <div className="video-part">
                                    <video id="Processer" width="100%" height="100%" loop muted>
                                        <source src={Procmainvideo} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className='d-flex spect-frame'>
                            {
                                processArr && processArr.map((item, index) => {
                                    return (
                                        <div ref={procssaddtoRefs} key={index} className=''>
                                            <div className="img-div common-tv-imgbottom-div">
                                                <img  loading='lazy' src={item.imgurl} alt="imgurl" className='img-fluid' />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>




            </div>
        </section>
    )
}
