import React from 'react'
import gooAssmainimg from '../assets/img/Assistant/Assistant.webp';
import gooAssdi1 from '../assets/img/Assistant/GetAnswers.webp';
import gooAssdi2 from '../assets/img/Assistant/Manage.webp';
import gooAssdi3 from '../assets/img/Assistant/Routine.webp';
import gooAssdi4 from '../assets/img/Assistant/control.webp';
import Frame from '../assets/img/dv-sec/frame.webp';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);

const gooAssArr = [
    { imgurl: gooAssdi1 },
    { imgurl: gooAssdi2 },
    { imgurl: gooAssdi3 },
    { imgurl: gooAssdi4 },
]

export default function GooASSec() {

    const gooAssSec = useRef(null);

    let gooAssCard = useRef([]);
    gooAssCard.current = [];

    const gooAssaddtoRefs = (item) => {
        if (item && !gooAssCard.current.includes(item)) {
            gooAssCard.current.push(item);
        }
    }

    window.$(document).ready(function () {

        gooAssCard.current.forEach((item, index) => {
            gsap.timeline({
                scrollTrigger: {
                    id: `section-${index + 1}`,
                    trigger: item,
                    // markers: true,
                    start: "top center+=100",
                    toggleActions: 'play none none reverse',
                    ease: "slow(0.5, 0.8)",
                    scrub: 1,
                    duration: 2,
                    triggerHook: 0,
                },
            }).from(item, {
                autoAlpha: 0,
                scale: '1.5',
            }).to(item, {
                autoAlpha: 1,
                scale: '1',
            })
        })

    })

    return (
        <section className="work-station section-space overflow-hidden">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7">
                        <div className="img-video-common-part">
                            <div className="main-img-commom">
                                <div className="fram-div">
                                    <img loading='lazy' src={Frame} alt="Frame" className='img-fluid' />
                                </div>
                                <div className="img-div common-tv-imgmain-div">
                                    <img loading='lazy' src={gooAssmainimg} alt="gooAssmainimg" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-md-5">
                        <div className='d-flex spect-frame'>
                            {
                                gooAssArr && gooAssArr.map((item, index) => {
                                    return (
                                        <div ref={gooAssaddtoRefs} key={index} className='mt-md-5'>
                                            <div className="img-div common-tv-imgbottom-div">
                                                <img loading='lazy' src={item.imgurl} alt="imgurl" className='img-fluid' />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>




                </div>

            </div>
        </section>
    )
}

